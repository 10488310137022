var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "upload_box" },
    [
      !_vm.disabled
        ? _c(
            "el-upload",
            {
              ref: "upload",
              staticStyle: { display: "inline-block", "margin-right": "5px" },
              attrs: {
                action: _vm.action,
                headers: { Authorization: _vm.token },
                name: "file",
                multiple: _vm.multiple,
                "show-file-list": false,
                disabled: _vm.disabled,
                "on-success": _vm.handleSuccess,
                "on-error": _vm.handleError,
                "on-progress": _vm.handleLoading,
                "before-upload": _vm.beforeUpload,
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    disabled: _vm.disabled,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t(_vm.buttonText)) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.disabled && _vm.fileList && _vm.fileList.length > 0
        ? _c(
            "el-button",
            {
              attrs: { type: "success", size: "small" },
              on: { click: _vm.downloadPackage },
            },
            [_vm._v("打包下载")]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "files-wrapper", staticStyle: { display: "flex" } },
        [
          _vm._l(_vm.fileList, function (item, index) {
            return _c(
              "div",
              {
                key: "et" + item.id,
                staticClass: "file-tag-list",
                style: { "margin-top": _vm.disabled ? "8px" : 0 },
              },
              [
                _vm.detailFlag
                  ? _c(
                      "el-tooltip",
                      { attrs: { effect: "dark", placement: "top" } },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("lang_file_name")) +
                                "：" +
                                _vm._s(item.fileName)
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("lang_upload_time")) +
                                "：" +
                                _vm._s(
                                  new Date(item.modifyTime).toLocaleString()
                                )
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("lang_file_size")) +
                                "：" +
                                _vm._s(item.size / 1000) +
                                "KB "
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            ref: "name_box",
                            refInFor: true,
                            staticClass: "file-name fileName shortName",
                            style: { "max-width": _vm.max_width + "px" },
                            on: {
                              click: function ($event) {
                                return _vm.previewUpload(item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.fileName))]
                        ),
                      ]
                    )
                  : _c(
                      "span",
                      {
                        ref: "name_boxes",
                        refInFor: true,
                        staticClass: "file-name fileName",
                        style: { "max-width": _vm.max_width + "px" },
                        on: {
                          click: function ($event) {
                            return _vm.previewUpload(item)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.fileName))]
                    ),
                _c("span", [
                  !_vm.disabled
                    ? _c(
                        "a",
                        {
                          staticClass: "file-list-replace",
                          staticStyle: { "margin-left": "0" },
                          attrs: { target: "_blank" },
                          on: {
                            click: function () {
                              return _vm.replaceUpload(item, index)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("lang_replace")))]
                      )
                    : _vm._e(),
                  _vm.signable && !_vm.disabled
                    ? _c("span", [_vm._v("|")])
                    : _vm._e(),
                  _vm.signable && !_vm.disabled
                    ? _c(
                        "a",
                        {
                          staticClass: "file-list-replace",
                          attrs: { target: "_blank" },
                          on: {
                            click: function ($event) {
                              return _vm.signFile(item, index)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("lang_form_datasource_user_sign"))
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.disabled ? _c("span", [_vm._v("|")]) : _vm._e(),
                  !_vm.disabled
                    ? _c(
                        "a",
                        {
                          staticClass: "file-list-delete",
                          on: {
                            click: function ($event) {
                              return _vm.handleRemove(item, index)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("lang_delete")))]
                      )
                    : _vm._e(),
                ]),
              ],
              1
            )
          }),
          _c("input", {
            ref: "fileInputRef",
            staticStyle: { display: "none" },
            attrs: { type: "file" },
            on: {
              change: function (e) {
                return _vm.changeInput(e)
              },
            },
          }),
          _vm.loading && !_vm.disabled
            ? _c("el-tag", { attrs: { type: "danger" } }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("lang_please_wait"))),
                  _c("i", { staticClass: "el-icon-loading" }),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("lang_preview"),
            width: "100%",
            top: "20px",
            "append-to-body": "",
            "close-on-click-modal": false,
            visible: _vm.showPreivewDlg,
            "custom-class": "pdf-viewer",
          },
          on: {
            close: function ($event) {
              _vm.showPreivewDlg = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _vm._v(" " + _vm._s(_vm.$t("lang_preview")) + " "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "warning", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.downloadFile(_vm.item)
                        },
                      },
                    },
                    [_vm._v("下载源文件")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("iframe", {
            attrs: {
              src: _vm.previewUrl + "#navpanes=1",
              id: "img-view-iframe",
              frameborder: "0",
              width: "100%",
              height: "100%",
            },
          }),
        ]
      ),
      _vm.showPdfOrImg == "img"
        ? _c("el-image-viewer", {
            attrs: { "on-close": _vm.closeViewer, "url-list": _vm.imgList },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }