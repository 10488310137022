<template>
  <div ref="upload_box">
    <el-upload
      v-if="!disabled"
      style="display: inline-block; margin-right: 5px"
      ref="upload"
      :action="action"
      :headers="{ Authorization: token }"
      name="file"
      :multiple="multiple"
      :show-file-list="false"
      :disabled="disabled"
      :on-success="handleSuccess"
      :on-error="handleError"
      :on-progress="handleLoading"
      :before-upload="beforeUpload"
    >
      <el-button size="small" type="primary" :disabled="disabled">
        {{ $t(buttonText) }}
      </el-button>
    </el-upload>
    <el-button type="success" v-if="disabled && fileList && fileList.length > 0" size="small" @click="downloadPackage"
      >打包下载</el-button
    >
    <div class="files-wrapper" style="display: flex">
      <div class="file-tag-list" v-for="(item, index) in fileList" :key="'et' + item.id" :style="{ 'margin-top': disabled ? '8px' : 0 }">
        <el-tooltip effect="dark" placement="top" v-if="detailFlag">
          <div slot="content">
            {{ $t('lang_file_name') }}：{{ item.fileName }}<br />
            {{ $t('lang_upload_time') }}：{{ new Date(item.modifyTime).toLocaleString() }}<br />
            {{ $t('lang_file_size') }}：{{ item.size / 1000 }}KB
          </div>
          <span
            class="file-name fileName shortName"
            :style="{ 'max-width': max_width + 'px' }"
            ref="name_box"
            @click="previewUpload(item)"
            >{{ item.fileName }}</span
          >
        </el-tooltip>
        <span class="file-name fileName" @click="previewUpload(item)" ref="name_boxes" v-else :style="{ 'max-width': max_width + 'px' }">{{
          item.fileName
        }}</span>
        <span>
          <!--   <a v-if="previewable" class="file-list-preview" target="_blank" @click="previewUpload(item)">{{ $t('lang_preview') }} </a> -->
          <!-- <span v-if="previewable">|</span> -->
          <!--  <a
            class="file-list-replace"
            target="_blank"
            @click="downloadFile(item)"
            v-if="downloadable"
            >{{ $t('lang_download') }}
          </a>
          <span v-if="downloadable && !disabled">|</span> -->
          <a class="file-list-replace" target="_blank" @click="() => replaceUpload(item, index)" v-if="!disabled" style="margin-left: 0">{{
            $t('lang_replace')
          }}</a>
          <span v-if="signable && !disabled">|</span>
          <a class="file-list-replace" target="_blank" @click="signFile(item, index)" v-if="signable && !disabled">{{
            $t('lang_form_datasource_user_sign')
          }}</a>
          <span v-if="!disabled">|</span>

          <a class="file-list-delete" @click="handleRemove(item, index)" v-if="!disabled">{{ $t('lang_delete') }}</a>
        </span>
      </div>
      <input ref="fileInputRef" type="file" style="display: none" @change="e => changeInput(e)" />
      <el-tag v-if="loading && !disabled" type="danger">
        <span>{{ $t('lang_please_wait') }}<i class="el-icon-loading"></i></span>
      </el-tag>
    </div>

    <el-dialog
      :title="$t('lang_preview')"
      width="100%"
      top="20px"
      append-to-body
      :close-on-click-modal="false"
      :visible="showPreivewDlg"
      custom-class="pdf-viewer"
      @close="showPreivewDlg = false"
    >
      <template #title>
        {{ $t('lang_preview') }}
        <el-button type="warning" @click="downloadFile(item)" size="mini">下载源文件</el-button>
      </template>
      <iframe :src="previewUrl + `#navpanes=1`" id="img-view-iframe" frameborder="0" width="100%" height="100%"></iframe>
    </el-dialog>
    <el-image-viewer v-if="showPdfOrImg == 'img'" :on-close="closeViewer" :url-list="imgList" />
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import StorageUtil from 'global-ui/packages/utils/StorageUtil';
import FileApi from '@/api/system/FileApi';

import http from '@/interceptor/xmlHttpRequest';
export default {
  name: 'EleUpload',
  components: {
    ElImageViewer
  },
  data() {
    return {
      token: '',
      loading: false,
      fileList: [],
      replaceIndex: 0,
      replaceFile: {},
      isValueChange: false, //判断是否是由于v-mode变化而引起的watch方法调用
      uploadCount: 0,
      showPreivewDlg: false,
      previewUrl: null,
      imgList: [],
      imgUrl: '',
      showPdfOrImg: 'pdf',
      detailFlag: false,
      fileUploadID: '',
      max_width: null
    };
  },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      }
    },

    /*是否只展示详情*/
    action: {
      type: String,
      default: '/river-fs/file/create'
    },

    /*是否只展示详情*/
    buttonText: {
      type: String,
      default: 'lang_upload_ttachments'
    },

    accept: {
      type: Array,
      default: () => []
    },
    /*文件限制个数*/
    limit: {
      type: Number,
      default: 10
    },
    /*文件限制个数*/
    maxSize: {
      type: Number,
      default: 50
    },
    multiple: {
      type: Boolean,
      default: false
    },
    /*是否只展示详情*/
    disabled: {
      type: Boolean,
      default: false
    },
    downloadable: {
      type: Boolean,
      default: true
    },
    signs: {
      type: Array,
      default() {
        return [];
      }
    },
    signable: {
      type: Boolean,
      default: false
    },
    /*是否只展示详情*/
    previewable: {
      type: Boolean,
      default: true
    }
  },
  filters: {
    textMaxLength(val) {
      if (val && val.length > 8) {
        return val.substring(0, 8) + '...';
      }
      return val;
    }
  },
  computed: {},
  watch: {
    value(curVal, oldVal) {
      if (this.isValueChange) {
        this.isValueChange = false;
      } else {
        this.getFileList(curVal);
      }
    }
  },
  created() {
    this.token = StorageUtil.getSessionStorage('token');
    this.getFileList(this.value);
  },
  mounted() {
    this.$nextTick(() => {
      this.max_width = this.$refs.upload_box.offsetWidth;
      if (!this.max_width) {
        this.max_width = '50';
      }
      if (this.disabled) {
        this.detailFlag = true;
      } else {
        this.detailFlag = false;
      }
    });
  },
  methods: {
    closeViewer() {
      this.showPdfOrImg = 'pdf';
    },

    //获取文件列表
    getFileList(fileIds) {
      this.loading = true;
      this.fileList = [];
      if (fileIds && fileIds.length) {
        FileApi.list({ ids: fileIds }).then(res => {
          this.loading = false;
          if (res.code == 1 && res.data.length != 0) {
            this.fileList = res.data;
          }
        });
      } else {
        this.fileList = [];
        this.loading = false;
      }
    },

    //上传之前
    beforeUpload(file) {
      let fileName = file.name;
      let fileSubfix = fileName.substring(fileName.lastIndexOf('.'), fileName.length);
      if (this.accept.length !== 0 && !this.accept.includes(fileSubfix.toLowerCase())) {
        this.$message.warning(this.$t('lang_file_format_error'));
        return false;
      }
      if (this.fileList.length === this.limit) {
        this.$message({
          type: 'error',
          message: this.$t('lang_files_max') + this.limit + '个'
        });
        return false;
      } else {
        this.uploadCount++;
        return true;
      }
    },
    //上传过程中
    handleLoading(event, file, fileList) {
      this.loading = true;
    },
    //上传成功后
    handleSuccess(res, file) {
      this.loading = false;
      this.fileList.push(res.data);
      this.updateValue();
      this.$nextTick(() => {
        // let box = document.querySelector('.upload-demo');
        let nameBox = document.querySelector('.file-name');
        if (this.$refs.upload_box.offsetWidth > nameBox.offsetWidth) {
          this.detailFlag = false;
        } else {
          this.max_width = this.$refs.upload_box.offsetWidth;
          this.detailFlag = true;
        }
      });
    },
    //上传失败
    handleError(file, fileList) {
      this.loading = false;
      this.$message.error(this.$t('lang_upload_fail'));
    },
    //更新v-mode的值
    updateValue() {
      this.uploadCount--;
      this.isValueChange = true;
      if (this.uploadCount === 0) {
        let value = this.fileList.map(item => item.id);
        this.$emit('input', value);
        this.$emit('success', this.fileList);
      }
    },

    //上传的文件删除时
    handleRemove(val, index) {
      this.fileList.splice(index, 1);
      let value = this.fileList.map(item => item.id);
      this.$emit('input', value);
      this.$emit('success', this.fileList);
    },
    //文件预览
    previewUpload(val) {
      this.fileUploadID = val.id;
      // FileApi.preview({ fileId: val.id });
      // 隐藏工具栏与导航栏
      // this.previewUrl = `/river-fs/file/view?fileId=${val.id}&token=${this.token}#toolbar=0&navpanes=1`;
      // this.previewUrl = `/river-fs/file/view?fileId=${val.id}&token=${this.token}#navpanes=1`;
      http.ajax({
        url: `/river-fs/file/view?fileId=${val.id}&token=${this.token}`,
        dataType: 'blob',
        timeout: 0,
        success: this.previewUploadSuccess
      });
    },
    previewUploadSuccess(result) {
      if (result.type && result.type.split('/')[0] == 'image') {
        this.showPdfOrImg = 'img';
        this.imgUrl = window.URL.createObjectURL(result);
        this.imgList = [];
        this.imgList.push(this.imgUrl);
      } else {
        this.showPdfOrImg = 'pdf';
        this.previewUrl = window.URL.createObjectURL(result); //这里调用window的URL方法
        this.showPreivewDlg = true;
      }
    },

    //文件替换
    replaceUpload(val, index) {
      this.replaceIndex = index;
      this.replaceFile = val;
      this.$refs.fileInputRef.dispatchEvent(new MouseEvent('click'));
      // document.getElementById('InputUpload').click();
    },
    changeInput(event) {
      let file = event.target.files[0];
      FileApi.upload({ file: file }).then(res => {
        this.fileList.splice(this.replaceIndex, 1, res.data);
        let value = this.fileList.map(item => item.id);
        this.$emit('input', value);
        this.$emit('success', this.fileList);
      });
    },
    //下载
    downloadFile(item) {
      FileApi.download({ fileId: this.fileUploadID });
    },
    //签章
    signFile(file, index) {
      this.$emit('sign', file);
    },
    //打包下载
    downloadPackage() {
      let fileIds = this.fileList.map((item) => item.fileId).join(",");
      window.location.href = `/river-fs/file/download/list?ids=${fileIds}&token=${this.token}`;
    },
  }
};
</script>

<style scoped lang="scss">
/deep/ .el-button--mini {
  padding: 5px 3px;
  font-size: 18px !important;
  transform: scale(0.8, 0.8);
}
.fileName {
  cursor: pointer;
}
.shortName {
  width: 100%;
  // max-width: 100%;
  flex: 1;
}
.files-wrapper {
  display: flex;
  flex-wrap: wrap;
  // width: 100%;
  justify-content: flex-start;
}
.file-tag-list {
  border: 1px solid #d9ecff;
  background-color: #ecf5ff;
  line-height: 20px;
  color: #409eff;
  margin-right: 6px;
  margin-bottom: 4px;
}
.file-name {
  display: block;
  // max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.file-list-preview {
  cursor: pointer;
}
.file-list-replace {
  cursor: pointer;
  margin: 0 5px;
}
.file-list-delete {
  cursor: pointer;
  color: red;
  text-decoration-line: none;
  margin-left: 5px;
}
/deep/.pdf-viewer {
  height: 95% !important;
  .el-dialog__body {
    height: calc(100% - 80px);
    overflow: hidden;
  }
}
</style>
